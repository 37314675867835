import { useEffect } from "react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCheckout } from "../../hooks/useCheckout";
import { LoadingSpinner } from "../loadingSpinner/loadingSpinner";
import { decodeHtmlCharCodes } from "../../utils/stringCodeFormatter";

export interface CheckOutFormProps {}
export function CheckOutForm(props: CheckOutFormProps) {
  const navigate = useNavigate();
  const { checkout, confirmCheckout, processing } = useCheckout();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const labelStyles = {
    fontWeight: "bold",
    mt: "1em",
    fontSize: "sm",
  };

  useEffect(() => {
    if (processing ===  2 ) navigate("/thankyou"); 
  },
    [processing, navigate]
  );


  return (
    processing ? <LoadingSpinner isLoading={processing === 1 ? true : false } /> :
    <Box p={"1em"}>
      <Heading
        lineHeight={1.1}
        fontSize={{ base: "2xl", sm: "3xl" }}
        textAlign="center"
        mb="1em"
      >
        Il tuo ordine
      </Heading>

      <Stack
        mt="1em"
        w={"100%"}
        border={"1px solid #d3d3d394"}
        direction={["column", "column", "row"]}
      >
        <Center p="1em">
          <Image
            boxSize={["100px", "150px", "xs"]}
            src={`${checkout.prodotto?.baseURL}${checkout.prodotto?.imagename}`}
            alt={decodeHtmlCharCodes(checkout.prodotto?.descrizione)}
          />
          <Box w={["xs", "md"]}>
            <Heading fontSize={["xs", "xs", "md"]} textAlign="center" mb="1em">
              {decodeHtmlCharCodes(checkout.prodotto?.productname)}
            </Heading>
              <Text p={"1em"} fontSize={["xs", "md"]}>
              {decodeHtmlCharCodes(checkout.prodotto?.descrizione)}
            </Text>
          </Box>
        </Center>
      </Stack>

      <Heading fontSize={{ base: "md" }} mt="1em" mb="1em" textAlign={"center"}>
        Compilare i dati con cura indicando l'indirizzo di spedizione per il
        prodotto.
      </Heading>
      <form onSubmit={handleSubmit(confirmCheckout)}>
        <Stack p={"1em"} direction={["column", "column", "row"]}>
          <Box w={["xs", "md"]}>
            <FormControl isInvalid={errors.nome} isRequired>
              <FormLabel sx={labelStyles} htmlFor="nome">
                Nome
              </FormLabel>
              <Input
                id="nome"
                defaultValue={checkout.nome}
                placeholder="nome"
                {...register("nome", {
                  required: "Informazione obbligatoria",
                })}
              />
              <FormErrorMessage>
                {errors.nome && errors.nome.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.cognome}>
              <FormLabel sx={labelStyles} htmlFor="cognome">
                Cognome
              </FormLabel>
              <Input
                id="cognome"
                defaultValue={checkout.cognome}
                placeholder="cognome"
                {...register("cognome", {
                  required: "Informazione obbligatoria",
                })}
              />
              <FormErrorMessage>
                {errors.cognome && errors.cognome.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.telefono}>
              <FormLabel sx={labelStyles} htmlFor="telefono">
                Telefono
              </FormLabel>
              <Input
                id="telefono"
                defaultValue={checkout.telefono}
                placeholder="telefono"
                {...register("telefono", {
                  required: "Informazione obbligatoria",
                  pattern: {
                    value: /^((00|\+)39[. ]??)??3\d{2}[-./ ]??\d{6,7}$/,
                    message: "Il formato del telefono non è valido",
                  },
                })}
              />

              <FormErrorMessage>
                {errors.telefono && errors.telefono.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.email}>
              <FormLabel sx={labelStyles} htmlFor="email">
                Email
              </FormLabel>
              <Input
                id="email"
                defaultValue={checkout.email}
                placeholder="email"
                {...register("email", {
                  required: "Informazione obbligatoria",
                  pattern: {
                    value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Email non valida",
                  },
                })}
              />

              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box w={["xs", "md"]} >
            <FormControl isRequired isInvalid={errors.indirizzo}>
              <FormLabel sx={labelStyles} htmlFor="indirizzo">
                Indirizzo
              </FormLabel>
              <Input
                id="indirizzo"
                defaultValue={checkout.indirizzo}
                placeholder="indirizzo"
                {...register("indirizzo", {
                  required: "Informazione obbligatoria",
                })}
              />
              <FormErrorMessage>
                {errors.indirizzo && errors.indirizzo.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.citta}>
              <FormLabel sx={labelStyles} htmlFor="citta">
                Città
              </FormLabel>
              <Input
                id="citta"
                defaultValue={checkout.citta}
                placeholder="citta"
                {...register("citta", {
                  required: "Informazione obbligatoria",
                })}
              />
              <FormErrorMessage>
                {errors.citta && errors.citta.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={errors.cap}>
              <FormLabel sx={labelStyles} htmlFor="cap">
                CAP
              </FormLabel>
              <Input
                id="cap"
                placeholder="cap"
                defaultValue={checkout.cap}
                {...register("cap", {
                  required: "Informazione obbligatoria",
                  pattern: {
                    value: /^\d{5}$/,
                    message: "Indicare un numero di 5 cifre",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.cap && errors.cap.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.provincia}>
              <FormLabel sx={labelStyles} htmlFor="provincia">
                Provincia
              </FormLabel>
              <Input
                id="provincia"
                defaultValue={checkout.provincia.toUpperCase()}
                placeholder="provincia"
                {...register("provincia", {
                  required: "Informazione obbligatoria",

                  pattern: {
                    value: /^[a-zA-Z]{2}$/,
                    message: "Indicare la provincia con la sigla (2 caratteri)",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.provincia && errors.provincia.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel sx={labelStyles} htmlFor="note">
                Note
              </FormLabel>
              <Textarea
                id="note"
                defaultValue={checkout.note}
                placeholder="note"
                {...register("note", {})}
              />
              <FormErrorMessage>
                {errors.note && errors.note.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </Stack>

        <Box p="1em" fontSize={["sm", "md"]}>
          <FormControl w={["xs", "md", "2xl", "3xl"]}>
            <FormLabel sx={labelStyles} htmlFor="privacy">
              Informativa ai sensi del Regolamento UE 2016/679
            </FormLabel>

            <Text mb="1em" fontSize={["sm", "md"]}>
              Dichiaro di accettare le Condizioni Generali d’Uso e di Vendita e
              il trattamento dei miei dati personali (dati identificativi e di
              contatto) strettamente necessari ai fini dell’esecuzione dei
              Condizioni Generali d’Uso e di Vendita. <Link
                href="https://www.wishlist.it/privacy-and-cookies-law"
                isExternal
              >
                Leggi la nostra privacy policy <ExternalLinkIcon mx="2px" />
              </Link>
            </Text>
            <Checkbox
                isInvalid
                id="privacy"
                defaultValue={checkout.privacy}
                placeholder="privacy"
                {...register("privacy", {
                  required: "Occorre accettare le privacy policy",
                })}
                size="sm"
            >
              Acconsento alla registrazione al sito per finalità strettamente
              connesse all’erogazione dei servizi on line
            </Checkbox>
            <FormErrorMessage>
              {errors.privacy && errors.privacy.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl w={["xs", "md", "2xl", "3xl"]}>
            <FormLabel sx={labelStyles} htmlFor="marketing">
              Autorizzazioni al contatto
            </FormLabel>

            <Text mb="1em" fontSize={["sm", "md"]}>
              Acconsento al trattamento dei miei dati personali (dati
              identificativi e di contatto), anche mediante profilazione, per
              finalità commerciali e/o promozionali relative ai prodotti e ai
              WishList (e.g. newsletter, inviti ad eventi, buoni sconto,
              promozioni ed altre offerte).
            </Text>

            <Checkbox
              id="marketing"
              defaultValue={checkout.privacy}
              placeholder="marketing"
                {...register("marketing", {})}
                size="sm"
            >
              Si, vorrei ricevere informazioni e/o promozioni da WishList
            </Checkbox>
            <FormErrorMessage>
              {errors.marketing && errors.marketing.message}
            </FormErrorMessage>
          </FormControl>
        </Box>

        <Stack p={"1em"} spacing={6} direction={["column", "row"]}>
          <Button w={"full"} onClick={() => navigate("/")}>
            Annulla
          </Button>
          <Button
            w={"full"}
            mt={4}
            colorScheme="wishlist"
            isLoading={isSubmitting}
            type="submit"
          >
            Conferma
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
