import * as React from "react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { doExcludeCategories } from "../../../features/products/categories";
import "./autocomplete.css";
import { FiltersCategoriesProps } from "./filterInterfaces";
import { TypeCategory } from "../../../app";

const AutocompleteCategories = ({
  currentFilters,
  setCurrentFilters,
  excludeCategories,
}: FiltersCategoriesProps) => {
  const [categories] = useState(doExcludeCategories(excludeCategories));
  const [currentDefault, setDefault] = useState(
    currentFilters.map((category) => ({
      value: category.key,
      label: category.value,
    }))
  );

  const options = categories.map((category:TypeCategory) => ({
    value: category.key,
    label: category.value,
  }));

  useEffect(() => {
    setDefault(
      currentFilters.map((category) => ({
        value: category.key,
        label: category.value,
      }))
    );
  }, [currentFilters]);

  const setSelectedCategories = (val: any) => {
    if (setCurrentFilters) {
      setCurrentFilters(
        val.map((item: { value: string; label: string }) => ({
          key: item.value,
          value: item.label,
        }))
      );
    }
  };

  return (
    <Select
      defaultValue={currentDefault}
      className="react-select-container"
      isMulti
      onChange={setSelectedCategories}
      options={options}
      placeholder="Categorie..."
    />
  );
};

export default AutocompleteCategories;
