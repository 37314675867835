import { VStack } from "@chakra-ui/react";
import { lazy, Suspense, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./App.css";
import { LoadingSpinner } from "./components/loadingSpinner/loadingSpinner";
import { useValidateSession } from "./hooks/index";
import ErrorView from "./components/errorView/errorView";

const Outlet = lazy(() =>
  import("react-router-dom").then((module) => ({ default: module.Outlet }))
);
const MenuHeader = lazy(() =>
  import("./layouts/index").then((module) => ({ default: module.MenuHeader }))
);
const FooterSection = lazy(() =>
  import("./layouts/index").then((module) => ({
    default: module.FooterSection,
  }))
);

const queryClient = new QueryClient();

function App() {
  let [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, onLoading, onError } = useValidateSession(
    searchParams.get("d")
  );

  useEffect(() => {
    if (user && user.d.length > 0 && location.pathname === "/") {
      navigate("/catalog");
    }
  });

  return (

    <>
      {onError && user.d.length === 0 && (
        <ErrorView error={{ status: "302", msg: "Sessione non valida" }} />
      )}

      {onLoading && <LoadingSpinner isLoading={onLoading} />}
      {
        user.d.length > 0 && (

          <QueryClientProvider client={queryClient}>
            <Suspense fallback={<LoadingSpinner isLoading={true} />}>
              <VStack p={0}>
                <MenuHeader {...user} />
                <Outlet />
                <FooterSection />
              </VStack>
            </Suspense>
          </QueryClientProvider>
        )}
    </>
  );
}

export default App;
