import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem, Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid
} from "@chakra-ui/react";
import { useState } from "react";
import { bconf, ProductProps, TypeCategory } from "../../app/index";
import {
  useFetchProducts,
  useFiltersBanner,
  useValidateSession
} from "../../hooks/index";
import { LoadingSpinner } from "../loadingSpinner/loadingSpinner";
import { PopOverBodyFilter } from "../popOverFilter";
import { DisplayError } from "./error";
import { Paginator } from "./paginator";
import ProductElement from "./product";
import { FiltersBanner } from "./searchBar/filtersBanner";

const CatalogContent = () => {
  const { user } = useValidateSession(null);
  const {
    result: { isLoading, isError, data, isSuccess, error },
    currentPage,
    setCurrentPage,
    querySearch,
    setquerySearch,
  } = useFetchProducts("", "", user.t); //FIXME: capire se manterene l'esclusione delle categorie

  const { filterBtnRef, filterIsOpen, filterOnClose, filterOnOpen } =
    useFiltersBanner();

  const [isOpen, setIsOpen] = useState(true);
  //const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  const doSearch = (
    selectedCat: TypeCategory[],
    selectedKeywords: string,
    rangeValues: number[]
  ): void => {
    filterOnClose();
    setquerySearch({
      categories: selectedCat,
      keyword: selectedKeywords,
      rangeValue: rangeValues,
    });

    setCurrentPage(1);
  };

  return (
    <>
      <Box className="catalog" id="grid-result" p="1em" w={"100%"} fontSize={["sm", "md"]} marginTop="0px">
        <Flex
          pt={2}
          pb={2}
          as="header"
          position="sticky"
          w="100%"
          backgroundColor="rgba(255, 
 255, 255, 0.8)"
          backdropFilter="saturate(180%) blur(5px)"
          zIndex="200"
          top="0"
        >
          <Grid templateColumns="repeat(6, 1fr)" w="100%">
            <GridItem w="100%" h="10" id="grid-result">
              <Popover
                isLazy
                isOpen={isOpen}
                onClose={close}
                arrowPadding={30}
                placement="bottom-start"
                boundary={"scrollParent"}
                closeOnBlur={true}
                closeOnEsc={true}
                colorScheme="wishlist"
                computePositionOnMount={true}
              >
                <PopoverTrigger>
                  <Button
                    rightIcon={<ChevronDownIcon />}
                    ref={filterBtnRef}
                    onClick={filterOnOpen}
                    bgColor={"wishlist.600"}
                    color={"#ffffff"}
                    _hover={{ bg: "wishlist.600" }}
                    _active={{
                      bg: "wishlist.600",
                      transform: "scale(0.98)",
                      borderColor: "wishlist.600",
                    }}
                    
                  >
                    Filtri
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>
                   
                  </PopoverHeader>
                  <PopoverBody>
                    <PopOverBodyFilter />
                  </PopoverBody>
                </PopoverContent>
              </Popover>

              <FiltersBanner
                isOpen={filterIsOpen}
                onClose={filterOnClose}
                btnRef={filterBtnRef}
                callbackSearch={doSearch}
                querySearch={querySearch}
                excludeCategories={""}
              />
            </GridItem>
            <GridItem w="100%" h="10" colSpan={5}>
              {isSuccess && (
                <Paginator
                  currentPage={currentPage}
                  totalPages={
                    data?.data
                      ? Math.ceil(data.data.total / bconf().totalProductPerPage)
                      : 0
                  }
                  setCurrentPage={setCurrentPage}
                />
              )}
            </GridItem>
          </Grid>
        </Flex>
        {isError && error && error instanceof Error && (
          <DisplayError error={error} />
        )}
        {isLoading && <LoadingSpinner isLoading={isLoading} />}
        <Center p={5} as="main">
          <SimpleGrid columns={[1, 2, 2, 3, 4]} spacing={5}>
            {isSuccess &&
              data &&
              data.data.results.map((element: ProductProps) => {
                return (
                  <ProductElement
                    {...element}
                    key={element.id}
                    baseURL={user.t?.ImageBaseURL}
                  />
                );
              })}
          </SimpleGrid>
        </Center>
        {isSuccess && (
          <Paginator
            currentPage={currentPage}
            totalPages={
              data?.data
                ? Math.ceil(data.data.total / bconf().totalProductPerPage)
                : 0
            }
            setCurrentPage={setCurrentPage}
          />
        )}
      </Box>
    </>
  );
};

export default CatalogContent;
