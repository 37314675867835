const colors = {
  wishlist: {
    50: "#eaf3fe",
    100: "#ccd9e7",
    200: "#adbfd2",
    300: "#8da6bf",
    400: "#6e8cac",
    500: "#547392",
    600: "#405972",
    700: "#2d4052",
    800: "#192633",
    900: "#010e16",
  },
};

export default colors;
