import JSON5 from "json5";


export const bconf = () => {

  if (!process.env.REACT_APP_COLLECTIONS) throw new Error("Collection configuration error");
  if (!process.env.REACT_APP_CATEGORIES) throw new Error("Categories configuration error");
  if (!process.env.REACT_APP_FASCIA_MAX) throw new Error("Max slider configuration error");

  return {    
    LOGIN: process.env.REACT_APP_LOGIN,
    CATEGORIES: JSON5.parse(process.env.REACT_APP_CATEGORIES),
    totalProductPerPage: 16,
    COLLECTIONS: JSON5.parse(process.env.REACT_APP_COLLECTIONS),
    FASCIA_MAX: parseInt(process.env.REACT_APP_FASCIA_MAX)
  };
};
 