import { CheckoutType } from "../app/types/checkoutType";
import axios from "axios";

export const callApiSendOrder = async (checkout: CheckoutType) => {
  try {
    const response = await axios.post("/api/sendOrder", checkout);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      /// console.log(error);

      throw new Error(error.response?.data);
      // do whatever you want with native error
      // if (error.response) {
      //   // The request was made and the server responded with a status code
      //   // that falls out of the range of 2xx
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      // }
    } else {
      console.log(error);
      throw error;
    }
  }
};
