import { CheckoutType, TBuy } from "../app/types/checkoutType";
import { ProductProps, WBToken } from "../app/types/types";

export const getBuy = (user: WBToken, product: ProductProps): TBuy => {
  const retBuy = {
    buy: {
      buyCredit51c3: false,
      buyCredit100: false,
    },
    credit51c3: user.credit51c3,
    credit100: user.credit100,
    creditExtra51c3: user.credit100 - user.credit51c3,
    fringebenefit: product.fringebenefit,
    fascia: product.fascia,
  };

  if (retBuy.fringebenefit === 1) {
    if (retBuy.fascia <= retBuy.credit51c3) {
      retBuy.buy.buyCredit51c3 = true;
    }
  } else {
    if (retBuy.fascia <= retBuy.credit100) {
      retBuy.buy.buyCredit100 = true;
    }
    // ******** old condition
    // if (retBuy.fascia <= retBuy.credit51c3) {
    //   retBuy.buy.buyCredit51c3 = true;
    // } else if (retBuy.fascia <= retBuy.creditExtra51c3) {
    //   retBuy.buy.buyCredit100 = true;
    // }
  }

  return retBuy;
};

// FIXME: Parallel function in API order. Same as in api/sendOrder/helpers/welfarebitOrder.ts
export const updateCheckoutCredit = (
  user: WBToken,
  checkout: CheckoutType
): WBToken => {
  let credit100: number = user.credit100;
  let credit51c3: number = user.credit51c3;

  if (checkout.prodotto) {
    if (checkout.prodotto.fringebenefit === 1) {
      if (checkout.prodotto.fascia <= user.credit51c3) {
        credit51c3 -= checkout.prodotto.fascia;
        credit100 -= checkout.prodotto.fascia;
      }
    } else {
      if (checkout.prodotto.fascia <= user.credit100) { 
        credit100 -= checkout.prodotto.fascia;
      }
     
    } 
  }

  if (credit100 < 0 || credit100 < credit51c3) {
    credit100 = 0;
    credit51c3 = 0;
  } else if (credit100 === 0 && credit51c3 > 0) {
    credit51c3 = 0;
  }

  const newUser = { ...user, credit100, credit51c3 };

  return newUser;
};
