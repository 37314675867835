import { TypeT } from './../app/types/types';
import { bconf, TypeQuerySearch } from "../app/index";
import { useState } from "react";
import { useQuery } from "react-query";
import { getProducts } from "../features/products/getProducts";

export const useFetchProducts = (
  collection: string,
  excludeCategories: string,
  token: TypeT | undefined
) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [querySearch, setquerySearch] = useState<TypeQuerySearch>({
    categories: [],
    keyword: "",
    rangeValue: [0, bconf().FASCIA_MAX ]
  });

  const result = useQuery(
    ["products", { collection, currentPage, querySearch, token }],
    async () => {
      return token && await getProducts(
        currentPage,
        collection,
        excludeCategories,
        querySearch,
        token
      );
    }, { refetchOnWindowFocus: false}
  );
  return {
    result,
    currentPage,
    setCurrentPage,
    querySearch,
    setquerySearch,
  };
};
